exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-change-password-tsx": () => import("./../../../src/pages/change-password.tsx" /* webpackChunkName: "component---src-pages-change-password-tsx" */),
  "component---src-pages-ekyc-service-ekyc-pending-review-[id]-tsx": () => import("./../../../src/pages/ekyc-service/ekyc/pending-review/[id].tsx" /* webpackChunkName: "component---src-pages-ekyc-service-ekyc-pending-review-[id]-tsx" */),
  "component---src-pages-ekyc-service-ekyc-pending-review-index-tsx": () => import("./../../../src/pages/ekyc-service/ekyc/pending-review/index.tsx" /* webpackChunkName: "component---src-pages-ekyc-service-ekyc-pending-review-index-tsx" */),
  "component---src-pages-ekyc-service-ekyc-transaction-history-[id]-tsx": () => import("./../../../src/pages/ekyc-service/ekyc/transaction-history/[id].tsx" /* webpackChunkName: "component---src-pages-ekyc-service-ekyc-transaction-history-[id]-tsx" */),
  "component---src-pages-ekyc-service-ekyc-transaction-history-index-tsx": () => import("./../../../src/pages/ekyc-service/ekyc/transaction-history/index.tsx" /* webpackChunkName: "component---src-pages-ekyc-service-ekyc-transaction-history-index-tsx" */),
  "component---src-pages-ekyc-service-face-capture-pending-review-[id]-tsx": () => import("./../../../src/pages/ekyc-service/face-capture/pending-review/[id].tsx" /* webpackChunkName: "component---src-pages-ekyc-service-face-capture-pending-review-[id]-tsx" */),
  "component---src-pages-ekyc-service-face-capture-pending-review-index-tsx": () => import("./../../../src/pages/ekyc-service/face-capture/pending-review/index.tsx" /* webpackChunkName: "component---src-pages-ekyc-service-face-capture-pending-review-index-tsx" */),
  "component---src-pages-ekyc-service-face-capture-transaction-history-[id]-tsx": () => import("./../../../src/pages/ekyc-service/face-capture/transaction-history/[id].tsx" /* webpackChunkName: "component---src-pages-ekyc-service-face-capture-transaction-history-[id]-tsx" */),
  "component---src-pages-ekyc-service-face-capture-transaction-history-index-tsx": () => import("./../../../src/pages/ekyc-service/face-capture/transaction-history/index.tsx" /* webpackChunkName: "component---src-pages-ekyc-service-face-capture-transaction-history-index-tsx" */),
  "component---src-pages-ekyc-service-face-compare-pending-review-[id]-tsx": () => import("./../../../src/pages/ekyc-service/face-compare/pending-review/[id].tsx" /* webpackChunkName: "component---src-pages-ekyc-service-face-compare-pending-review-[id]-tsx" */),
  "component---src-pages-ekyc-service-face-compare-pending-review-index-tsx": () => import("./../../../src/pages/ekyc-service/face-compare/pending-review/index.tsx" /* webpackChunkName: "component---src-pages-ekyc-service-face-compare-pending-review-index-tsx" */),
  "component---src-pages-ekyc-service-face-compare-transaction-history-[id]-tsx": () => import("./../../../src/pages/ekyc-service/face-compare/transaction-history/[id].tsx" /* webpackChunkName: "component---src-pages-ekyc-service-face-compare-transaction-history-[id]-tsx" */),
  "component---src-pages-ekyc-service-face-compare-transaction-history-index-tsx": () => import("./../../../src/pages/ekyc-service/face-compare/transaction-history/index.tsx" /* webpackChunkName: "component---src-pages-ekyc-service-face-compare-transaction-history-index-tsx" */),
  "component---src-pages-ekyc-service-transactions-[id]-tsx": () => import("./../../../src/pages/ekyc-service/transactions/[id].tsx" /* webpackChunkName: "component---src-pages-ekyc-service-transactions-[id]-tsx" */),
  "component---src-pages-ekyc-service-transactions-index-tsx": () => import("./../../../src/pages/ekyc-service/transactions/index.tsx" /* webpackChunkName: "component---src-pages-ekyc-service-transactions-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-t-application-[id]-tsx": () => import("./../../../src/pages/t/application/[id].tsx" /* webpackChunkName: "component---src-pages-t-application-[id]-tsx" */),
  "component---src-pages-t-application-index-tsx": () => import("./../../../src/pages/t/application/index.tsx" /* webpackChunkName: "component---src-pages-t-application-index-tsx" */),
  "component---src-pages-t-binding-authentication-history-tsx": () => import("./../../../src/pages/t/binding/authentication-history.tsx" /* webpackChunkName: "component---src-pages-t-binding-authentication-history-tsx" */),
  "component---src-pages-t-binding-registered-device-tsx": () => import("./../../../src/pages/t/binding/registered-device.tsx" /* webpackChunkName: "component---src-pages-t-binding-registered-device-tsx" */),
  "component---src-pages-t-client-index-tsx": () => import("./../../../src/pages/t/client/index.tsx" /* webpackChunkName: "component---src-pages-t-client-index-tsx" */),
  "component---src-pages-t-ekyc-[id]-tsx": () => import("./../../../src/pages/t/ekyc/[id].tsx" /* webpackChunkName: "component---src-pages-t-ekyc-[id]-tsx" */),
  "component---src-pages-t-ekyc-index-tsx": () => import("./../../../src/pages/t/ekyc/index.tsx" /* webpackChunkName: "component---src-pages-t-ekyc-index-tsx" */),
  "component---src-pages-t-home-tsx": () => import("./../../../src/pages/t/home.tsx" /* webpackChunkName: "component---src-pages-t-home-tsx" */),
  "component---src-pages-t-management-admin-group-tsx": () => import("./../../../src/pages/t/management/admin-group.tsx" /* webpackChunkName: "component---src-pages-t-management-admin-group-tsx" */),
  "component---src-pages-t-management-admin-tsx": () => import("./../../../src/pages/t/management/admin.tsx" /* webpackChunkName: "component---src-pages-t-management-admin-tsx" */),
  "component---src-pages-t-messaging-messaging-history-tsx": () => import("./../../../src/pages/t/messaging/messaging-history.tsx" /* webpackChunkName: "component---src-pages-t-messaging-messaging-history-tsx" */),
  "component---src-pages-t-messaging-messaging-template-tsx": () => import("./../../../src/pages/t/messaging/messaging-template.tsx" /* webpackChunkName: "component---src-pages-t-messaging-messaging-template-tsx" */),
  "component---src-pages-t-messaging-new-message-tsx": () => import("./../../../src/pages/t/messaging/new-message.tsx" /* webpackChunkName: "component---src-pages-t-messaging-new-message-tsx" */),
  "component---src-pages-t-product-[code]-tsx": () => import("./../../../src/pages/t/product/[code].tsx" /* webpackChunkName: "component---src-pages-t-product-[code]-tsx" */),
  "component---src-pages-t-product-index-tsx": () => import("./../../../src/pages/t/product/index.tsx" /* webpackChunkName: "component---src-pages-t-product-index-tsx" */),
  "component---src-pages-t-resources-system-resource-[category]-index-tsx": () => import("./../../../src/pages/t/resources/system-resource/[category]/index.tsx" /* webpackChunkName: "component---src-pages-t-resources-system-resource-[category]-index-tsx" */),
  "component---src-pages-t-resources-system-resource-[category]-resource-key-tsx": () => import("./../../../src/pages/t/resources/system-resource/[category]/[resourceKey].tsx" /* webpackChunkName: "component---src-pages-t-resources-system-resource-[category]-resource-key-tsx" */),
  "component---src-pages-t-resources-system-resource-index-tsx": () => import("./../../../src/pages/t/resources/system-resource/index.tsx" /* webpackChunkName: "component---src-pages-t-resources-system-resource-index-tsx" */),
  "component---src-pages-t-system-service-configuration-tsx": () => import("./../../../src/pages/t/system/service-configuration.tsx" /* webpackChunkName: "component---src-pages-t-system-service-configuration-tsx" */),
  "component---src-pages-t-system-tenant-information-tsx": () => import("./../../../src/pages/t/system/tenant-information.tsx" /* webpackChunkName: "component---src-pages-t-system-tenant-information-tsx" */),
  "component---src-pages-t-system-tenanted-service-health-tsx": () => import("./../../../src/pages/t/system/tenanted-service-health.tsx" /* webpackChunkName: "component---src-pages-t-system-tenanted-service-health-tsx" */),
  "component---src-pages-t-task-execution-history-tsx": () => import("./../../../src/pages/t/task/execution-history.tsx" /* webpackChunkName: "component---src-pages-t-task-execution-history-tsx" */),
  "component---src-pages-t-task-task-job-tsx": () => import("./../../../src/pages/t/task/task-job.tsx" /* webpackChunkName: "component---src-pages-t-task-task-job-tsx" */)
}

