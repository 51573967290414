import { createSlice, createSelector } from "@reduxjs/toolkit";
import { setAuth, clearAuth } from "./authSlice";
import { setT } from "./authSlice";

const historyRegisteredActions = [setAuth, clearAuth];

interface HistoryState {
  lastAction: string | null;
  lastLoc: string;
  serviceHistory: string[];
}

const initialState: HistoryState = {
  lastAction: null,
  lastLoc: "",
  serviceHistory: [],
};

const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Register action that could be shown in history/lastAction
    historyRegisteredActions.forEach((a) => {
      builder.addCase(a, (state) => {
        state.lastAction = a.type;
      });
    });
    builder.addCase(setT.fulfilled, (state, action) => {
      state.serviceHistory.push(action.payload.funcName);
    });
  },
});

const selectHist = (state: { history: HistoryState }) => state.history;
export const selectHistState = createSelector([selectHist], (s: HistoryState) => {
  return {
    serviceHistory: s.serviceHistory,
    lastService: s.serviceHistory[s.serviceHistory.length - 1],
  };
});

export default historySlice.reducer;
