import React from "react";
import {
  DashboardIcon,
  ApplicationIcon,
  ProductIcon,
  ClientIcon,
  BindingIcon,
  DeviceIcon,
  AuthHistoryIcon,
  EKYCIcon,
  MessagingIcon,
  NewMessageIcon,
  MessageHistoryIcon,
  TemplateIcon,
  ResourceIcon,
  ScheduleTaskIcon,
  TaskIcon,
  ExecutionHistoryIcon,
  AdminGearIcon,
  AdminIcon,
  AdminGroupIcon,
  SystemIcon,
  ConfigIcon,
  InfoIcon,
  HeartBeatIcon,
  PendingReviewIcon,
  TransactionHistoryIcon,
  EkycFaceCompareIcon,
  FaceCaptureIcon,
} from "components/styled-icons";

export interface NavConfigItem {
  name: string;
  path?: string;
  badgeContent?: React.ReactNode;
  badgeKey?: string;
  icon: React.ReactNode;
  children?: NavConfigItem[];
}

export interface NavConfigGroup {
  name: string;
  children: NavConfigItem[];
  disableDivider?: boolean;
}

const pathRoot = "/t";

export const traditionalNavConfigNested: NavConfigGroup[] = [
  // {
  //   name: "Home",
  //   children: [
  //     {
  //       name: "Dashboard",
  //       path: "",
  //       icon: <DashboardIcon />,
  //     },
  //   ],
  // },
  {
    name: "Application",
    children: [
      {
        name: "Application",
        path: `${pathRoot}/application`,
        icon: <ApplicationIcon />,
      },
      {
        name: "Product",
        path: `${pathRoot}/product`,
        icon: <ProductIcon />,
      },
    ],
  },
  {
    name: "Client",
    children: [
      {
        name: "Client",
        path: `${pathRoot}/client`,
        icon: <ClientIcon />,
      },
      {
        name: "Device Binding",
        icon: <BindingIcon />,
        children: [
          {
            name: "Registered Device",
            path: `${pathRoot}/binding/registered-device`,
            icon: <DeviceIcon />,
          },
          {
            name: "Authentication History",
            path: `${pathRoot}/binding/authentication-history`,
            icon: <AuthHistoryIcon />,
          },
        ],
      },
      {
        name: "eKYC",
        path: `${pathRoot}/ekyc`,
        icon: <EKYCIcon />,
      },
      {
        name: "Messaging",
        icon: <MessagingIcon />,
        children: [
          {
            name: "Send New Message",
            path: `${pathRoot}/messaging/new-message`,
            icon: <NewMessageIcon />,
          },
          {
            name: "Messaging Template",
            path: `${pathRoot}/messaging/messaging-template`,
            icon: <TemplateIcon />,
          },
          {
            name: "Messaging History",
            path: `${pathRoot}/messaging/messaging-history`,
            icon: <MessageHistoryIcon />,
          },
        ],
      },
    ],
  },
  {
    name: "Management",
    children: [
      {
        name: "System Resource",
        path: `${pathRoot}/resources/system-resource`,
        icon: <ResourceIcon />,
      },
      {
        name: "Schedule Task",
        icon: <ScheduleTaskIcon />,
        children: [
          {
            name: "Task",
            path: `${pathRoot}/task/task-job`,
            icon: <TaskIcon />,
          },
          {
            name: "Execution History",
            path: `${pathRoot}/task/execution-history`,
            icon: <ExecutionHistoryIcon />,
          },
        ],
      },
      {
        name: "Administration",
        icon: <AdminIcon />,
        children: [
          {
            name: "Admin",
            path: `${pathRoot}/management/admin`,
            icon: <AdminGearIcon />,
          },
          {
            name: "Admin Group",
            path: `${pathRoot}/management/admin-group`,
            icon: <AdminGroupIcon />,
          },
        ],
      },
      {
        name: "System",
        icon: <SystemIcon />,
        children: [
          // {
          //   name: "Encryption Key",
          //   path: "",
          //   icon: <KeyIcon />,
          // },
          {
            name: "Tenant Information",
            path: `${pathRoot}/system/tenant-information`,
            icon: <InfoIcon />,
          },
          {
            name: "Service Configuration",
            path: `${pathRoot}/system/service-configuration`,
            icon: <ConfigIcon />,
          },
          {
            name: "Service Health",
            path: `${pathRoot}/system/tenanted-service-health`,
            icon: <HeartBeatIcon />,
          },
        ],
      },
    ],
  },
];

const ekycPathRoot = "/ekyc-service";
export const ekycServiceNavConfigNested: NavConfigGroup[] = [
  {
    name: "eKYC Service",
    disableDivider: true,
    children: [
      {
        name: "Dashboard",
        path: "",
        icon: <DashboardIcon />,
      },
      {
        name: "eKYC",
        badgeKey: "EKYC",
        icon: <EKYCIcon />,
        children: [
          {
            name: "Pending Review",
            badgeKey: "EKYC",
            path: `${ekycPathRoot}/ekyc/pending-review`,
            icon: <PendingReviewIcon />,
          },
          {
            name: "Transaction History",
            path: `${ekycPathRoot}/ekyc/transaction-history`,
            icon: <TransactionHistoryIcon />,
          },
        ],
      },
      {
        name: "Face Capture",
        badgeKey: "FACE_CAPTURE",
        icon: <FaceCaptureIcon />,
        children: [
          {
            name: "Pending Review",
            badgeKey: "FACE_CAPTURE",
            path: `${ekycPathRoot}/face-capture/pending-review`,
            icon: <PendingReviewIcon />,
          },
          {
            name: "Transaction History",
            path: `${ekycPathRoot}/face-capture/transaction-history`,
            icon: <TransactionHistoryIcon />,
          },
        ],
      },
      {
        name: "Face Compare",
        badgeKey: "FACE_COMPARE",
        icon: <EkycFaceCompareIcon />,
        children: [
          {
            name: "Pending Review",
            badgeKey: "FACE_COMPARE",
            path: `${ekycPathRoot}/face-compare/pending-review`,
            icon: <PendingReviewIcon />,
          },
          {
            name: "Transaction History",
            path: `${ekycPathRoot}/face-compare/transaction-history`,
            icon: <TransactionHistoryIcon />,
          },
        ],
      },
      {
        name: "Provider Configuration",
        // path: `${ekycPathRoot}/config/provider-configuration`,
        icon: <ConfigIcon />,
      },
    ],
  },
];
